import React from 'react'
import { Body, Illustration, Section, Wrapper, Description, TextGroup, Title } from '../style'
import { InboxButton, InboxButtonsGroup, NewLink } from './style'

const Confirmation = ({
  buttonText,
  confirmationTitle,
  confirmationDescription,
  email,
  illustration,
  link,
}: any) => {
  return (
    <Section>
      <Wrapper>
        <Illustration>
          <img src={illustration.url} alt={illustration.alt} />
        </Illustration>

        <Body>
          <div>
            <TextGroup>
              <Title>
                <h3>
                  {confirmationTitle[0]}
                  <br /> <span>{confirmationTitle[1]}</span>
                </h3>
              </Title>
              <Description>
                <span>
                  {confirmationDescription[0]} <strong>{email}</strong> {confirmationDescription[1]}
                </span>
              </Description>
            </TextGroup>

            {/* <Button>{buttonText}</Button> */}
          </div>
        </Body>
        <InboxButtonsGroup>
          <InboxButton>
            <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank">
              <span>Open Gmail</span>
              <img
                src="https://cryptruserui.cdn.prismic.io/cryptruserui/900aecff-7e83-4a3f-a0fb-0193bb293bb8_gmail_logo.svg"
                alt="gmail inbox"
              />
            </a>
          </InboxButton>
          <InboxButton>
            <a href="https://outlook.live.com/mail/0/inbox" target="_blank">
              <span>Open Outlook</span>
              <img
                src="https://cryptruserui.cdn.prismic.io/cryptruserui/ce2db00c-a2a3-403c-ae18-899618b23128_outlook_logo.svg"
                alt="outlook inbox"
              />
            </a>
          </InboxButton>
        </InboxButtonsGroup>
        <NewLink>
          {link[0]} <a href={link[1].path}>{link[1].text}</a>
        </NewLink>
      </Wrapper>
    </Section>
  )
}

export default Confirmation
