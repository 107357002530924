import React from 'react'
import ValuePropositions from '../../components/forms/value_propositions'
import Confirmation from '../../components/forms/confirmation'
import { SignFormStyle } from '../../components/forms/style'

const DevelopersConfirmationEmailPage = ({ location }: any) => {
  let urlParams = new URLSearchParams(location.search)
  let myEmail = urlParams.get('email')

  const title = ['Get Beautiful', 'User Account Experience']
  const illustration = {
    url:
      'https://cryptruserui.cdn.prismic.io/cryptruserui/48b22eaf-1bc3-457e-82f6-d75d0a223f9e_cryptr_illustration_developers_confirmation_email.svg',
    alt: 'Developers sign up - confirmation email',
  }
  const confirmationTitle = ['Open your mailbox', 'To confirm your Account']

  const confirmationDescription = ['We sent an email to', 'containing a confirmation link.']

  const link = [
    'Or',
    {
      text: 'send a new login link again',
      path: '/developers/sign-up',
    },
  ]

  const valuePropositions = [
    {
      strongText: 'No credit card',
      normalText: '(of course)',
    },
    {
      strongText: '< 15 min',
      normalText: 'Your authentication',
    },
    {
      strongText: 'No passwords',
      normalText: 'for your users',
    },
  ]
  return (
    <SignFormStyle>
      {/* 
        TODO: Refacto helmet to be able to use it outside the layout 
        and add the following parameters:  
          -> helmetTitle="Sign Up | Free community plan forever"
          -> helmetDescription="Secured & Frictionless Customer Login, quick and simple to set up. Codeless & editable self-hosted authentication."
          -> helmetImage={'/metatag_image_cryptr.png'}
          -> helmetUrl={`https://www.cryptr.co/developers/sign-up`}
      */}
      <ValuePropositions
        title={title}
        illustration={illustration}
        valuePropositions={valuePropositions}
      />
      <Confirmation
        link={link}
        confirmationTitle={confirmationTitle}
        confirmationDescription={confirmationDescription}
        email={myEmail}
        illustration={illustration}
      />
    </SignFormStyle>
  )
}

export default DevelopersConfirmationEmailPage
