import styled from '@emotion/styled'
import tw from 'twin.macro'

export const NewLink = styled.div`
  ${tw`
    mt-6
    text-sm
    text-gray-700
    text-center
  `}
  a {
    ${tw`
      text-indigo-600
      hover:text-indigo-500
      cursor-pointer
    `}
  }
`

export const InboxButtonsGroup = styled.div`
  ${tw`
    mt-6
    flex
    flex-row
    items-center
    justify-center
    space-x-3
  `}
`

export const InboxButton = styled.div`
  a {
    ${tw`
      inline-flex
      py-2
      px-12
      border
      border-gray-200
      rounded-md
      shadow-sm
      bg-white
      text-sm
      font-medium
      text-gray-500
      hover:bg-gray-100
    `}
  }
  span {
    ${tw`
      sr-only
    `}
  }
  img {
    ${tw`
      w-5
      h-5
    `}
  }
`
